import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layout/PageLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Imprint`}</h1>
    <h2>{`Information according to § 5 TMG`}</h2>
    <p>{`AMAI GmbH`}<br parentName="p"></br>{`
`}{`Schwarzwaldstraße 39`}<br parentName="p"></br>{`
`}{`76137 Karlsruhe ‍`}</p>
    <h2>{`Contact`}</h2>
    <p>{`Phone: +49 721 27664476`}<br parentName="p"></br>{`
`}{`E-Mail: `}<a parentName="p" {...{
        "href": "mailto:info@am.ai",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`info@am.ai`}</a></p>
    <h2>{`Register court`}</h2>
    <p>{`Amtsgericht Mannheim, HRB 731955`}</p>
    <h2>{`Seat of the company`}</h2>
    <p>{`Karlsruhe`}</p>
    <h2>{`Sales tax identification number`}</h2>
    <p>{`according to §27 a Umsatzsteuergesetz: DE321276268`}</p>
    <h2>{`CEO and responsible for the content according to § 55 Abs. 2 RStV`}</h2>
    <p>{`Dr.-Ing. Jürgen Stumpp`}<br parentName="p"></br>{`
`}{`Leonard Plotkin`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      